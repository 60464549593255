let hero_slider = document.querySelector('.hero-slider');

if(hero_slider){
  $('.hero-slider').slick({
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  });
}