import './components/hero_slider';
import './components/hero_image';
import Modal from './lib/Modal';

let modals = document.querySelectorAll('[data-modal]');

modals.forEach(modal => {
  new Modal(modal)
});

// google analytics button tracking
if (typeof gtag === 'function') {
  document.body.addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-location')) {
      const button = e.target;
      gtag('event', 'button_click', {
        'button_location': button.dataset.location,
        'button_label': button.innerText
      });
    }
  });
}
